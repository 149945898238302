@import 'src/styles/colors';
@import 'src/styles/vars';

.popup {
  background-color: white;
  border-radius: 6px;
  bottom: 130px;
  font-family: SuisseIntl-Regular-M, sans-serif;
  font-size: 13px;
  line-height: 16px;
  position: absolute;
  right: 10px;
  transition: all $transition_timing;
  width: 380px;
  z-index: 50;
  .header {
    align-items: center;
    background-color: $WCMAccent;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    display: flex;
    font-family: SuisseIntl-SemiBold-M, sans-serif;
    height: 43px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
  }
  .title {
    flex-grow: 1;
    line-height: 20px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .headerButton {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    height: 28px;
    vertical-align: middle;
    width: 28px;
    svg {
      backface-visibility: hidden;
      transition: transform 0.3s;
    }
    path {
      fill: white;
    }
  }
  .rotate180 {
    transform: rotate(-180deg);
  }
  .details {
    border-bottom: 1px solid $grayLight;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-left: 1px solid $grayLight;
    border-right: 1px solid $grayLight;
    padding: 20px 10px;
  }
  .progressBar {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
  }
  .items {
    max-height: 160px;
    overflow-y: auto;
    transition: all 0.5s;
  }
}

.itemCard {
  align-items: center;
  color: $grayDark;
  display: flex;
  height: 32px;
  .itemTitleContainer {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
    overflow: hidden;
  }
  .itemTitle {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .itemIconContainer {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 32px;
    justify-content: center;
    margin-left: 5px;
    width: 32px;
  }
  .itemButton {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    height: 32px;
    width: 32px;
  }
  .itemProgress {
    flex-shrink: 0;
    text-align: right;
    width: 110px;
  }
  .successIcon {
    color: #008642;
    height: 16px;
    width: 16px;
  }
  .warningIcon {
    color: $danger;
    height: 16px;
    width: 16px;
  }
}

@media (min-width: $breakpoint_desktop-small) {
  .sidebarMargin {
    transform: translateX(-360px);
  }
}

@media (max-width: $breakpoint_desktop-small - 1) {
  .popup {
    width: 310px;
    &.popupWithMarkers {
      bottom: 180px;
    }
    &.popupExpanded {
      bottom: 240px;
    }
    &.popupWithMarkersAndExpanded {
      bottom: 290px;
    }
  }
}
