.playButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-flex;
  vertical-align: middle;
  &:disabled {
    cursor: not-allowed;
  }
  .iconWrapper {
    display: flex;
    padding: 2px;
  }
  .disabledIcon {
    path {
      fill: #c4c4c4;
    }
  }
  svg {
    min-width: 17px;
  }
}
