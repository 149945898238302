@import 'src/styles/colors';
@import 'src/styles/vars';

.dialog {
  position: absolute !important;
  z-index: $errorPopupZIndex !important;
  &.hideDialog {
    display: none;
  }
  .paper {
    align-items: center;
    border: 1px solid $grayLighter;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
    display: flex;
    padding: 54px 36px;
    width: 304px;
    .errorTitle {
      color: $grayDark;
      font-family: SuisseIntl-SemiBold-M, sans-serif;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 11px;
    }
    .errorText {
      color: $gray;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 26px;
      word-break: break-word;
      a {
        color: $WCMAccent;
      }
    }
    .goBackBtn {
      align-items: center;
      background-color: $WCMAccent;
      border: 1px solid $WCMAccent;
      color: $white;
      cursor: pointer;
      display: flex;
      font-family: SuisseIntl-Regular-M, sans-serif;
      font-size: 13px;
      height: 35px;
      justify-content: center;
      line-height: 16px;
      width: 81px;
      &:hover {
        background-color: $WCMAccentHover;
      }
    }
  }
  .dialogBackdrop {
    background-color: transparent !important;
  }
}
